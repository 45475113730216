<template>
  <v-dialog v-model="show" max-width="600">
    <v-card class="pl-5 pr-5">
      <v-container>
        <v-row style="justify-content: center;">
          <v-col md="8" style="text-align: center;">
            <h4> {{`Recibo `}} </h4>
          </v-col>

          <v-col cols="12">
            <p class="mb-0">{{ pagamentoRecebimento }} para {{ dados.Contacts.name }} a importância de {{ dados.totalAmount | currency }} ({{ dados.totalAmount | currencyExtensive }}) referente à <span v-if="isImprimir">{{ dados.jsonFlags.descriptionReceipts }}</span> <v-text-field 
            v-else
            :rules="rules"
            v-model="dados.jsonFlags.descriptionReceipts"
            dense class="pt-0 mt-0 d-inline-block"
            ></v-text-field></p>

            <p>Pium, <span v-if="isImprimir">{{ dtString }}</span> <v-menu
                        v-else
                        v-model="menuDateInicial"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">

                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dtString"
                                v-mask="['##/##/####']"
                                @input="testeDate('inicial', dados.jsonFlags.dtReceipts)"
                                dense
                                :rules="rules"
                                class="pt-0 mt-0 d-inline-block"
                                v-on="on">
                                <template v-slot:append>
                                <v-icon v-on="on">
                                    event
                                </v-icon>
                                </template>
                            </v-text-field>
                            </template>

                        <v-date-picker
                            v-model="dados.jsonFlags.dtReceipts"
                            no-title
                            scrollable
                            locale="pt-br"
                            @input="closeModalDatePicker('inicial', dados.jsonFlags.dtReceipts)"
                            :color="variables.colorPrimary">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="clearDateFilter('inicial', dados.jsonFlags.dtReceipts)">Limpar</v-btn>
                            <v-btn text color="primary" @click="closeModalDatePicker('inicial', dados.jsonFlags.dtReceipts)">OK</v-btn>
                        </v-date-picker>
                      </v-menu></p>

            <div class="box-assinatura">
              <v-divider />
              <span>Assinatura</span>
            </div>
          </v-col>


          <v-col cols="12" style="text-align: center;" class="d-flex justify-center no-print">
            <v-btn
              class="br-btn mr-3 no-print"
              color="secondary"
              @click="salvar(false)"
              :loading="loading"
            >
              Salvar
            </v-btn>

            <v-btn
              class="br-btn no-print"
              color="error"
              @click="salvar(true)"
              :loading="loading"
            >
              Imprimir
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import moment from 'moment'
import variables from '@/assets/styles/helpers/_variables.scss'
//eslint-disable-next-line
import { cloneDeep } from 'lodash'

moment.locale('pt')

const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  // props:['show', 'record'],
  name: 'ModalCategoriasRecibo',

  data: () => ({
    show: false,
    rules: false,
    dtInicial: '',
    dtString: '',
    menuDateInicial: false,
    isImprimir: false,
    loading: false,
    dados: {
      type: '',
      totalAmount: 0,
      Contacts: {
        name: ''
      },
      jsonFlags: {
        dtReceipts: '',
        descriptionReceipts: '',
      },
    },
  }),

  mounted () {
    const self = this
    Events.$on('modalCadastro::recibo', val => {
      self.dados = cloneDeep(val.record)
      const {jsonFlags} = self.dados
      if (!jsonFlags) {
        self.dados.jsonFlags = {
          dtReceipts: self.dados.dtPaid,
          descriptionReceipts: self.dados.description
        }
      } else if (!jsonFlags.dtReceipts) {
        self.dados.jsonFlags = {
          dtReceipts: self.dados.dtPaid,
          descriptionReceipts: self.dados.description
        }
      }

      vm.dtString = moment(self.dados.jsonFlags.dtReceipts).format('LL') 

      self.show = true
    })

    const vm = this
    
    vm.dtInicial = moment().format('YYYY-MM-DD')
  },

  watch: {
		dtInicial (v) {
			this.$emit('update:date', v)
		}
	},

  methods: {
    ...mapActions(namespaceStore, ['submit']),

    closeModal () {
      Events.$emit('close::modal::categoria')
    },

    testeDate (dt, dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        switch (dt) {
          case 'inicial':
            this.dtInicial = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
          case 'final':
            this.dtFinal = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
        }
      }
    },
    closeModalDatePicker (dt, date) {
      switch (dt) {
        case 'inicial':
          this.dtString = moment(date, 'YYYY-MM-DD').format('LL') 
          this.menuDateInicial = false
          return
        case 'final':
          this.dtFinalString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.menuDateFinal = false
          return
      }
    },

    async salvar (isPrint) {
      this.loading = true

      const data = {
        isEdit: true,
        record: cloneDeep(this.dados)
      }
      
      delete data.record.Accounts
      delete data.record.Attachments
      delete data.record.Contacts
      delete data.record.CostCenters
      delete data.record.Entity
      this.submit(data).then(() => {
          successSnackbar('Lançamento editado com sucesso.')

          if (isPrint) {
            this.isImprimir = true
            setTimeout(() => {
              window.print()
            }, 100)
          }
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
       }).finally(() => {
        // self.modal.loading = false
        this.loading = false
      })
      
    }

    // updateLancamento () {
    //   const self = this
    //   self.modal.loading = true

    //   const data = {
    //     record: self.record,
    //     isEdit: self.modal.isEdit,
    //     isTransfer: false
    //   }

    //   self.submit(data).then(() => {
    //     successSnackbar('Recibo emitido.')
    //   }).catch(e => { errorSnackbar(e) }).finally(() => {
    //     self.closeModal()
    //     Events.$emit('categorias::update::tables')
    //     self.modal.loading = false
    //   })
    // }
  },

  computed: {
    variables: () => variables,

    pagamentoRecebimento () {
      return this.dados.type === 'D' ? 'Paguei(amos)' : 'Recebi(emos)'
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  // .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }

  .box-assinatura {
    margin: 0 auto;
    max-width: 300px;
    text-align: center;

    margin-top: 50px;
  }
</style>